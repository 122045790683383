import React from "react";
import { CtaLink, Paragraph } from "@purpurds/purpur";
import { croppedImageUrl } from "@telia/b2x-image-api";

import styles from "./b2x-customer-quote.module.scss";

export const B2xCustomerQuote = ({
  imgUrl,
  imgAlt,
  quote,
  quotee,
  href,
  linkText,
}: {
  quote: string;
  quotee: string;
  imgUrl?: string;
  imgAlt?: string;
  href?: string;
  linkText?: string;
}) => {
  return (
    <div className={`${styles.wrapper} ${imgUrl ? "" : styles.noImage}`}>
      <figure className={styles.quoteWrapper}>
        {imgUrl && (
          <div className={styles.imgWrapper}>
            <img
              data-testid="customer quote image"
              src={croppedImageUrl(imgUrl, 254, 339)}
              alt={imgAlt || ""}
            />
          </div>
        )}

        <div className={styles.textWrapper}>
          <div className={styles.textContent}>
            <div className={styles.quoteSign}>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 42 32"
                fill="none"
              >
                <path
                  d="M9.67864 32C7.05265 32 4.76429 30.9557 2.81356 28.8671C0.937853 26.704 0 23.9441 0 20.5874C0 17.1562 0.712768 13.9487 2.1383 10.9651C3.63887 7.98138 5.73966 5.51981 8.44067 3.58042C11.1417 1.56643 14.2554 0.372964 17.7817 0V6.60139C16.056 6.75058 14.3304 7.49653 12.6047 8.83919C10.9541 10.1818 9.71615 11.8975 8.89084 13.986C11.892 13.986 14.2554 14.9557 15.981 16.8951C17.7817 18.7599 18.682 20.8112 18.682 23.049C18.682 25.3613 17.7817 27.4499 15.981 29.3147C14.1803 31.1049 12.0795 32 9.67864 32ZM22.9586 20.5874C22.9586 17.1562 23.6714 13.9487 25.0969 10.9651C26.5975 7.98138 28.6983 5.51981 31.3993 3.58042C34.1003 1.56643 37.214 0.372964 40.7403 0V6.60139C39.0147 6.75058 37.289 7.49653 35.5634 8.83919C33.9128 10.1818 32.6748 11.8975 31.8495 13.986C34.8506 13.986 37.214 14.9557 38.9396 16.8951C40.7403 18.7599 41.6407 20.8112 41.6407 23.049C41.6407 25.3613 40.7403 27.4499 38.9396 29.3147C37.139 31.1049 35.0382 32 32.6373 32C30.0113 32 27.7229 30.9557 25.7722 28.8671C23.8965 26.704 22.9586 23.9441 22.9586 20.5874Z"
                  fill="black"
                  fillOpacity="0.44"
                />
              </svg>
            </div>
            <div className={styles.blockBody}>
              <blockquote
                cite={href}
                data-testid="customer block quote"
                className={styles.blockquote}
              >
                {quote}
              </blockquote>
              <Paragraph aria-hidden data-testid="customer quote paragraph">
                {quotee}
              </Paragraph>

              {href && linkText && (
                <div className={styles.linkWrapper}>
                  <CtaLink fullWidth variant="secondary" href={href}>
                    {linkText}
                  </CtaLink>
                </div>
              )}
            </div>
            <div className={styles.textWrapperBackground} />
          </div>
        </div>
        <figcaption className={styles.figcaption}>{quotee}</figcaption>
      </figure>
    </div>
  );
};
